<template>
    <signup-form />
</template>

<script>
import signupForm from '@/patterns/signupForm';

export default {
    components: {
        signupForm
    }
}
</script>
